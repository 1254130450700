<template>
    <div class="content_body backfff pad_15">
        <div class="pad_15">
            <div class="padbm_15">公司信息</div>
            <div class="">
                <el-form label-width="100px" class="demo-ruleForm" style="">
                    <el-form-item label="公司名称：">
                        <div>苏州哲联有限公司--实施质控部</div>
                    </el-form-item>
                    <el-form-item label="公司简称：">
                        <div>
                            <span>哲联</span>
                            <span class="marlt_20 color_primary" @click="editCompany">修改</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="企业LOGO：">
                        <div class="dis_flex  flex_y_center">
                            <img src="../../assets/img/ios.png">
                            <div class="marlt_20 color_primary">修改</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="企业法人：">
                        <div>zx</div>
                    </el-form-item>
                    <el-form-item label="联系电话：">
                        <div>
                            <span>13008013320</span>
                            <span class="marlt_20 color_primary" @click="editPhone">修改</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="企业地址：">
                        <el-input class="width_220" size="medium"></el-input>
                    </el-form-item>
                    <el-form-item label="企业类目：">
                        <div>美容医美美发</div>
                    </el-form-item>
                    <el-form-item label="入驻时间：">
                        <div>1999-06-16</div>
                    </el-form-item>
                    <el-form-item label="在职员工：">
                        <div>50人</div>
                    </el-form-item>
                    <el-form-item label="部门合计：">
                        <div>50</div>
                    </el-form-item>
                    <el-form-item label="门店合计：">
                        <div>50</div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="pad_15">
            <div class="padbm_15 color_gray">
                <span>开店咨询专业顾问</span>
                <span class="marlt_20">咨询热线：110</span>
                <span class="marlt_20 color_primary">发布服务需求</span>
            </div>
            <el-table size="small"
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px;"
                    row-key="id"
                    border
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column
                        prop="date"
                        label="功能列表"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="已开通功能"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="使用业务">
                </el-table-column>
            </el-table>
        </div>
        <!--弹框-->
        <el-dialog :title="companyTitle" :visible.sync="showCompany" width="30%">
            <div v-if="companyTitle == '修改公司简称'">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" :inline="true">
                    <el-form-item label="公司简称:" prop="name">
                        <el-input v-model="name" placeholder="" size="mini"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div v-else-if="companyTitle == '解绑联系人手机号'">
                <div class="marbm_15">
                    联系人手机号码将用于验证认证，提现，订购，等高危操作，请谨慎修改
                </div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" :inline="true" class="demo-form-inline">
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="新联系手机:" prop="phone">
                                <el-input v-model="phone" placeholder="" size="mini"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="短信验证码:" prop="code">
                                <div class="dis_flex">
                                    <div>
                                        <el-input v-model="code" placeholder="" size="mini"></el-input>
                                    </div>
                                    <div class="marlt_10 color_primary">发送验证码</div>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showCompany = false" size="medium">取 消</el-button>
                <el-button type="primary" @click="addSubmit" size="medium" v-if="companyTitle == '修改公司简称'">保 存
                </el-button>
                <el-button type="primary" @click="showCompany = false" size="medium"
                           v-else-if="companyTitle == '解绑联系人手机号'">确认绑定
                </el-button>
            </div>
        </el-dialog>

        <!--弹框-->
        <el-dialog title="解绑联系人手机号" :visible.sync="showPhone" width="30%">
            <div>
                <div class="marbm_15">
                    联系人手机号码将用于验证认证，提现，订购，等高危操作，请谨慎修改
                </div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" :inline="true" class="demo-form-inline">
                    <el-row>
                        <div v-if="showPhoneState == false">
                            <el-col :span="24">
                                <el-form-item label="手机号:" prop="phone" label-width="95px">
                                    <div>13008013320</div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="短信验证码:" prop="code">
                                    <div class="dis_flex">
                                        <div>
                                            <el-input v-model="code" placeholder="" size="mini"></el-input>
                                        </div>
                                        <div class="marlt_10 color_primary">发送验证码</div>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </div>
                        <div v-else-if="showPhoneState == true">
                            <el-col :span="24">
                                <el-form-item label="新联系手机:" prop="phone">
                                    <el-input v-model="phone" placeholder="" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24">
                                <el-form-item label="短信验证码:" prop="code">
                                    <div class="dis_flex">
                                        <div>
                                            <el-input v-model="code" placeholder="" size="mini"></el-input>
                                        </div>
                                        <div class="marlt_10 color_primary">发送验证码</div>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </div>
                    </el-row>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showPhone = false" size="medium">取 消</el-button>
                <el-button type="primary" @click="nextStep" size="medium" v-if="showPhoneState ==false">下一步</el-button>
                <el-button type="primary" @click="showPhone = false" size="medium" v-else-if="showPhoneState == true">
                    确认绑定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showCompany: false, //公司简称
                showPhone: false,
                showPhoneState: false,
                companyTitle: '修改公司简称',
                imageUrl: '../../assets/img/ios.png',
                tableData: [{
                    id: 1,
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    id: 2,
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1517 弄'
                }, {
                    id: 3,
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄',
                    children: [{
                        id: 31,
                        date: '2016-05-01',
                        name: '王小虎',
                        address: '上海市普陀区金沙江路 1519 弄'
                    }, {
                        id: 32,
                        date: '2016-05-01',
                        name: '王小虎',
                        address: '上海市普陀区金沙江路 1519 弄'
                    }]
                }, {
                    id: 4,
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1516 弄'
                }],
                ruleForm: {
                    name: '',
                    phone: '',
                    code: '',
                },
                rules: {
                    name: [{required: true, message: '请输入活动名称', trigger: 'blur'},],
                    phone: [{required: true, message: '请输入活动名称', trigger: 'blur'},],
                    code: [{required: true, message: '请输入活动名称', trigger: 'blur'},],
                }


            }
        },
        methods: {
            //修改公司简称
            editCompany: function () {
                var that = this;
                that.showCompany = true;
                that.companyTitle = '修改公司简称';
            },
            //修改手机号
            editPhone: function () {
                var that = this;
                that.showPhone = true;
                that.showPhoneState = false;
            },
            addSubmit: function () {
                var that = this;
                that.companyTitle = '解绑联系人手机号';
            },
            //下一步
            nextStep: function () {
                var that = this;
                that.showPhoneState = true;
            }
        },
        mounted() {

        }

    }
</script>

<style scoped lang="scss">
    .labelDiv {
        background: yellow;
        border-radius: 4px;
        padding: 2px;
    }

    .el-form-item {
        margin-bottom: 12px;
    }


</style>